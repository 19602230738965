.modal-dialog {
  max-width: 700px !important;
  /* height: 80%; */
}
.modal-content {
  height: 100%;
}
.modal-body {
  height: 80%;
}
