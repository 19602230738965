#tinyMCE1 {
  height: 100%;
}
#tinyMCE21 {
  height: 100%;

  border-right: 1px solid gray;
  padding-left: 2px;
}
#tinyMCE22 {
  height: 100%;

  padding-left: 2px;
}
#tinyMCE31 {
  height: 100%;

  border-right: 1px solid gray;
  padding-left: 2px;
}
#tinyMCE32 {
  height: 100%;

  padding-left: 2px;
  border-right: 1px solid gray;
}
#tinyMCE33 {
  height: 100%;

  padding-left: 2px;
}
.tinyMCE-container-editors {
  height: 80%;
  border: 1px solid #707070;
  width: 100%;
  margin-top: 20px;
  margin-left: auto;
}

.column-width-input {
  width: 55px;
  padding-left: 5px;
}
