.number-circle {
  -moz-boder-radius: 15px;
  border-radius: 15px;
  background-color: rgb(240, 189, 69);
  color: white;
  text-align: center;
  font-size: 18px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: block;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 10px;
  font-weight: bold;
  min-width: 30px;
}
.ta-workspace-name {
  display: flex;
  flex-direction: row;
}
.available-objects-div {
  overflow-y: scroll;
  width: 350px;
  height: 450px;
}
.applied-objects-div {
  width: 350px;
  height: 450px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}
.ta-report-name {
  margin-left: 20px;
}
.indent-ten {
  margin-left: 10px;
}
.parent-folder > * {
  padding-left: 20px !important;
}

.rstcustom__rowContents {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.rstcustom__rowContents:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.selected-node {
  display: flex;
  flex-direction: row;
}
.close-button {
  cursor: pointer;
  margin-right: 10px;
  font-size: 11px;
  margin-bottom: 5px;
}

.select-icon-dd {
  width: 94px;
}
.select-increment-dd {
  width:94px
}

.rstcustom__node {
  height: 25px;
}
.ReactVirtualized__Grid__innerScrollContainer {
  max-height: 100px;
}
/* .rst__tree {
  height: 100%;
}
.rst__placeholder {
  height: 100%;
} */