.stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100px;
  }
  
  .input-group {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 55px;
    border: 1px solid #ccc;
  }
  
  .number-input {
    text-align: center;
    border: none;
    height: 30px;
    line-height: 30px;
    padding: 0;
    width:80%;
    margin-left:1px
  }
  
  .control-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 15px;
    color: #333;
    font-size: 13px;
    padding: 0;
  }
  
  .control-button:disabled {
    color: #ccc;
    cursor: default;
  }
  