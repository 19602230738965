.app {
  /* text-align: center; */
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.app-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.app-header {
  /* background-color: #222; */
  height: 50px;
  padding: 20px;
  margin-bottom: 20px;
  /* color: white; */
  display: flex;
  align-items: center;
}
.app-title {
  font-weight: 600px;
  font-size: 20px;
}
.app-title {
  font-size: 1.5em;
}

.app-intro {
  font-size: large;
}
.ovis {
  width: 100%;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  min-width: 100px;
  text-align: center;
}

.login-msg {
  margin-bottom: 10px;
}
.ovis-tabs {
  /* display: inline-flex; */
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin-right: 40px;
  min-width: 225px;
  overflow: auto;
  flex: 0 0 auto;
  /* width: 16%; */
  /* height: 50%; */
}

.ovis-tabs > * {
  padding: 10px;
  padding-right: 20px;
  cursor: pointer;
}
.ovis-tab:hover {
  color: rgba(0, 126, 167, 0.7);
  text-decoration: underline rgba(0, 126, 167, 1);
}
.ovis-tab-selected {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline rgba(0, 126, 167, 1);
}

.ta-modal {
  border: 1px solid black;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 300px;
}
.ta-modal-hide {
  display: none;
}

.logout {
  right: 20px;
  position: absolute;
  cursor: pointer;
}

.ta-button {
  cursor: pointer;
  /* height: auto; */
  border-style: none;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 28px;
  white-space: nowrap;
  height: fit-content;
}
.ta-button-primary {
  background-color: rgba(0, 126, 167, 1);
  border: 1px solid rgba(0, 126, 167, 1);
  color: #fff;
}
.ta-button-secondary {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(112, 112, 112, 1);
  color: rgba(112, 112, 112, 1);
}
.ta-button-disabled {
  background-color: rgba(181, 181, 181, 1);
  border: 1px solid rgba(181, 181, 181, 1);
  color: rgba(255, 255, 255, 1);
  cursor: not-allowed;
}
.ta-add-button-right {
  margin-left: auto !important;
}
.ta-info-text {
  font-style: italic;
  font-weight: 300;
}
.tab-area {
  display: flex;
  height: 100%;
  /* overflow: auto; */
  overflow: hidden;
}
.ovis-tabs {
  margin-right: 10px;
}

.user-button {
  margin-right: 10px;
  margin-left: 10px;
}

.toggle-button-group {
  -webkit-user-select: none !important; /* Chrome all / Safari all */
  -moz-user-select: none !important; /* Firefox all */
  -ms-user-select: none !important; /* IE 10+ */
  user-select: none !important;
  height: 24px;
  margin-top: 3px;
  box-shadow: none !important;
  border: solid 1px #ccc;
  line-height: 24px;
  margin-right: 6px;
}
.toggle-button {
  font-size: 13px !important;
  line-height: 19px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  /* padding-top: 0px !important;
  padding-bottom: 7px !important; */
  min-width: 23px !important;
}
.toggle-button:focus {
  outline: none;
}
.button-override {
  background-color: rgba(0, 126, 167, 1) !important;
  color: white !important;
}
.button-override::after {
  opacity: 0 !important;
}
