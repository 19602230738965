.workspace-title-div {
  display: flex;
  margin-bottom: 10px;
}

.delete-dialog-option-text {
  margin-left: 10px;
}
.delete-dialog-option {
  margin-bottom: 10px;
}
.error-div {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 2px;
  font-size: 12px;
}
.validation-error-div {
  max-height: 200px;
  overflow: auto;
}

.section-heading {
  font-weight: 500;
  border-bottom: 1px solid #ccc;
}

.sso-config-container {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 6px;
}
.sso-config-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.drag-order-container {
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid #ccc;
}

.settings-row-container-white {
  padding: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}
.settings-row-container-gray {
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.config-subtitle {
  font-size: 14px;
  font-weight: 500;
}

.instruction-text {
  font-style: italic;
  font-size: 12px;
}

.page-title {
  font-size: 18px;
  font-weight: 500;
  color: rgb(33, 37, 41);
  margin-bottom: 10px;
}
