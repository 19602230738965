.workspace {
  display: inline-flex;
  width: 100%;
}

.report-name {
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}
.report-description-input {
  width: 100%;
  height: 100px;
}
.report-span {
  cursor: pointer;
  padding: 4px;
}
.report-span:hover {
  color: rgba(0, 126, 167, 1);
  background-color: rgba(62, 171, 203, 0.2);
}
.report-icon {
  padding-right: 4px;
}
.report-selected {
  background-color: rgba(62, 171, 203, 0.3);
  border: 1px solid rgba(62, 171, 203, 1);
  border-radius: 2px;
}
