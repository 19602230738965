.ta-file-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.file-dd {
  padding: 0px;
  z-index: 10000;
  background: white;
  border: 1px solid rgba(88, 89, 91, 1);
  width: 85px;
  padding: 5px;
  position: absolute;
  right: 59px;
}
.file-dd-item {
  cursor: pointer;
  font-size: 14px;
  padding-left: 6px;
}
.ta-heading-text {
  font-size: 14px;
}
.ta-file-span {
  cursor: pointer;
}
/* .ta-file-span:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
}

.ta-file-span:hover > svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
} */

.ta-file-list-container {
  width: 100%;
  overflow: auto;
  padding-left: 20px;
  position: absolute;
  top: 144px;
  bottom: 0px;
}

.ta-files {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rstcustom__rowLabel {
  width: 100%;
  padding-left: 4px;
}

.rstcustom__row {
  width: 100%;
}
.rstcustom__node {
  height: 25px !important;
}
/* .rstcustom__row:hover svg {
  pointer-events: all;
  fill: rgba(0, 126, 167, 1);
}
.rstcustom__rowWrapper:hover {
  opacity: 1;
} */

.rstcustom__row svg {
  pointer-events: none;
  fill: white;
}

.file-mgr-action-btn {
  border: none;
  background: white;
}
.right-spacer {
  margin-right: 30px;
}
.left-spacer {
  margin-left: 30px;
}

.rstcustom__collapseButton {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill='%2358595B' /%3E%3Cpath d='M0 0h24v24H0z' fill='none' /%3E%3C/svg%3E")
    no-repeat center !important;
  transform: rotate(90deg);
  transform-origin: 53% 22%;
}

.rstcustom__expandButton {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' fill='%2358595B' /%3E%3Cpath d='M0 0h24v24H0z' fill='none' /%3E%3C/svg%3E")
    no-repeat center !important;
  transform: translate3d(-2%, -33%, 0);
}

.rstcustom__collapseButton::after {
  border: none;
}
.rstcustom__expandButton::after {
  border: none;
}
.ta-file-item {
  padding-left: 6px;
}

/* .ta-file-item:hover {
  background-color: rgba(66, 133, 244, 0.14);
}

.ta-file-item:hover svg {
  pointer-events: all;
  fill: rgba(0, 126, 167, 1);
}
svg:hover {
  fill: black !important;
} */
.rf-folder-icon {
  padding-right: 5px;
  margin-top: 3px;
  fill: black;
}
.ta-file-item svg {
  pointer-events: none;
  fill: white;
}

.file-mgr-action-btn:focus,
.rstcustom__toolbarButton:focus {
  outline: none !important;
}

.ReactVirtualized__Grid[aria-label="grid"] {
  padding-top: 10px;
  padding-bottom: 10px;
}
