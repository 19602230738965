.ovis-tab-view {
  height: 100%;
}
#tinyMCE {
  height: 100%;
}
#tinyMCE:hover {
  outline: 2px solid #2276d2;
}
.tinyMCE-wrapper {
  height: 100%;
}

.tinyMCE-container {
  height: 60%;
  display: flex;
  justify-content: center;
  margin-top: 38px;
}
.message-container {
  border: 1px solid #707070;
  position: relative;
  width: 250px;
}
.tinymce-body {
  padding: 6px;
  position: absolute;
  top: 28px;
  bottom: 0px;
  width: 100%;
  font-size: 14px;
}

.mce-floatpanel {
  left: 485px !important;
  top: 150px !important;
}
.status-div {
  margin-top: 5px;
}
.msg-header {
  padding-left: 4px;
  line-height: 26px;
  font-size: 14px;
  height: 26px;
}

.feed-container {
  margin-top: 10px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  /* height: 100%; */
}
