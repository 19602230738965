.ta-workspace-name {
  font-size: 14px;
  font-style: italic;
}
.ta-group-name {
  font-size: 16px;
  font-weight: 400;
}
.ta-workspaces {
  padding-bottom: 10px;
}
