.vl {
  border-left: 1px solid rgba(160, 160, 160, 1);
  height: 90%;

  margin-left: 10px;
  bottom: 20px;
}
.applied-groups {
  /*   position: relative;
  top: 44px; */
  padding-left: 40px;
  width: 100%;
}
.input-box {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #707070;
  background-image: url(../../../img/search.png);
  background-repeat: no-repeat;
  background-position: 0px 6px;
  background-size: 16px;
  text-indent: 24px;
  font-size: 16px;
}

.input-box::placeholder {
  font-style: italic;
  font-size: 16px;
}

.input-box:focus {
  border-bottom-color: #4285f4;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #4285f4;
  background-image: url(../../../img/search-focus.png);
}

.input-box:focus::placeholder {
  color: #4285f4;
}

.client {
  display: flex;
  flex-direction: row;
}
.client-text {
  font-size: 20px;
  padding-top: 4px;
}
.action-buttons {
  display: flex;
  margin-left: auto;
}

.dropdown {
  padding-left: 10px;
  min-width: 200px;
  height: 30px;
}
.dropdown > * {
  /* background-color: white; */
}

.text-input {
  border-radius: 4px;
  border: 1px solid #707070;
  /* text-indent: 24px; */
  font-size: 16px;
}

.text-input::placeholder {
  font-style: italic;
  font-size: 16px;
}

.text-input:focus {
  border-color: #4285f4;
  outline: none;
}
