/*Container, container body, iframe*/
/* .mce-tinymce,
#tinyMCE_ifr {
  min-height: 95% !important;
}
.mce-floatpanel {
  min-height: none !important;
} */
/*Container body*/
/* .mce-container-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
} */
/*Editing area*/
/* .mce-container-body .mce-edit-area {
  position: absolute;
  top: 34px;
  bottom: 37px;
  left: 0;
  right: 0;
} */
/*Footer*/
/* .mce-tinymce .mce-statusbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
} */
.tox-editor-container{
  z-index: 0;
}
