.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; /* 40px height (20+20) */
  border-right: 10px solid gray;
  margin-right: 10px;
  margin-top: 5px;
}

.daily-select-group {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
