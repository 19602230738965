.user-group-title-div {
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;
}
.group-item {
  display: "flex";

  font-size: "16px";
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 4px;
  cursor: pointer;
}

.group-item-selected {
  background-color: rgba(62, 171, 203, 0.3);
  border: 1px solid rgba(62, 171, 203, 1);
  border-radius: 2px;
}
.group-item:hover {
  color: rgba(0, 126, 167, 1);
  background-color: rgba(62, 171, 203, 0.2);
}
