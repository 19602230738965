.ta-file-list {
  height: 100%;
}
.file-dd {
  padding: 0px;
  background: white;
  border: 1px solid rgba(88, 89, 91, 1);
  width: 85px;
  padding: 5px;
  position: absolute;
  right: 59px;
}
.file-dd-item {
  cursor: pointer;
  font-size: 14px;
  padding-left: 6px;
}
.folder-icon {
  padding-right: 5px;
  margin-top: 3px;
}
.select-all {
  margin-left: 10px;
  font-size: 11px;
  margin-top: 5.5px;
  color: rgba(0, 126, 167, 1);
  cursor: pointer;
  user-select: none;
}
.ta-heading-text {
  font-size: 14px;
}
.ta-file-span {
  cursor: pointer;
}
.ta-file-span:hover {
  color: rgba(0, 126, 167, 1);
  text-decoration: underline;
  text-decoration-color: rgba(0, 126, 167, 1);
}

.ta-file-span:hover > svg {
  fill: rgba(0, 126, 167, 1);
  transition: all ease 0.3s;
}
.ta-report-list-container {
  padding-top: 5px;
  border: 1px solid gray;
  height: 100%;
  margin-top: 20px;
}
.ta-ar {
  text-align: right;
  display: flex;
}
.ta-ar > * {
  padding-left: 50px;
}

.react-list-select-item {
  padding: 0.3em 0.6em;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-list-select-item.is-selected {
  -webkit-user-select: inherit;
  -moz-user-select: inherit;
  -ms-user-select: inherit;
  -o-user-select: inherit;
  user-select: inherit;

  background: #d7e7ff;
}

.ta-file-title {
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.ta-top-level-folder {
  height: 100%;
}
.ta-folder-selected {
  fill: rgba(0, 126, 167, 1);
}
.draggable {
  display: flex;
  width: 100%;
}
.selected {
  background: #d7e7ff;
}
.upload-folders {
  border: 1px solid gray;
  margin-top: 10px;
  height: 300px;
  width: 465px;
  overflow: auto;
}
.new-folder {
  margin-right: auto !important;
}
.disabled {
  background-color: rgba(181, 181, 181, 1) !important;
  border: 1px solid rgba(181, 181, 181, 1);
  color: rgba(255, 255, 255, 1) !important;
  cursor: not-allowed !important;
}
